import { render, staticRenderFns } from "./our-community-guidelines.vue?vue&type=template&id=3d559320&scoped=true"
import script from "./our-community-guidelines.vue?vue&type=script&lang=js"
export * from "./our-community-guidelines.vue?vue&type=script&lang=js"
import style0 from "./our-community-guidelines.vue?vue&type=style&index=0&id=3d559320&prod&lang=css&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3d559320",
  null
  
)

export default component.exports