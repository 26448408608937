<template>
  <div class="child-safety">
    <section class="main">
      <h2 class="title">Superlive Child Safety Policy</h2>
      <p class="content">
        At Superlive, the safety and well-being of children are our top
        priorities. We have a zerotolerance policy toward any form of abuse,
        exploitation, or sexualization involving minors. Violations of this
        policy may result in account removal and reporting to law enforcement or
        child protection agencies.
      </p>
    </section>

    <section>
      <h2 class="title">Policies</h2>
      <div class="section-sub">
        <h2 class="title">Age Restriction</h2>
        <ul class="content-list">
          <li>Superlive is intended for users aged 18 years and older.</li>
          <li>
            Any attempt to falsify age to access the platform is strictly
            prohibited and may result in account suspension or removal.
          </li>
        </ul>
      </div>
      <div class="section-sub">
        <h2 class="title">Prohibited Activities</h2>
        <p>
          Superlive prohibits all forms of child exploitation and abuse,
          including but not limited to:
        </p>
        <ul class="content-list">
          <li>
            <span class="item-title">
              Child Sexual Abuse Material (CSAM):
            </span>
            <ul class="content-list">
              <li>
                Sharing, creating, or distributing content involving child
                sexual abuse or exploitation.
              </li>
              <li>
                Depictions of child nudity, sexualization, or fetishization in
                any form, including artistic or digital representations.
              </li>
              <li>
                CSAM detection is conducted using advanced AI technologies
                combined with manual review to identify and remove harmful
                content proactively.
              </li>
            </ul>
          </li>
          <li>
            <span class="item-title"> Sexualization of Minors: </span>
            <ul class="content-list">
              <li>Discussions, role-playing, or acts that sexualize minors.</li>
              <li>
                Use of sexualized clothing or behaviors intended to exploit or
                harm minors.
              </li>
            </ul>
          </li>
          <li>
            <span class="item-title">
              Harmful or Dangerous Acts Involving Minors:
            </span>
            <ul class="content-list">
              <li>
                Depicting, encouraging, or threatening physical or psychological
                abuse, coercion, or dangerous activities involving minors.
              </li>
              <li>
                Promoting neglect, trafficking, or any form of exploitation.
              </li>
            </ul>
          </li>
        </ul>
      </div>
      <div class="section-sub">
        <h2 class="title">Restrictions on Appearances in Content</h2>
        <ul class="content-list">
          <li>
            <b>Children with Adults in Streams:</b> Minors may not appear in
            livestreams, videos, or any content alongside adults under any
            circumstances. This measure ensures the highest level of protection
            against potential risks.
          </li>
          <li>
            Minors are strictly prohibited from appearing in any livestreams,
            videos, or content on the platform, whether alone or accompanied by
            others.
          </li>
          <li>Creating accounts on behalf of minors is prohibited.</li>
        </ul>
      </div>
      <div class="section-sub">
        <h2 class="title">Prohibited Users</h2>
        <ul class="content-list">
          <li>
            Individuals convicted of crimes against children, such as sexual
            abuse, neglect, or trafficking, are banned from using Superlive.
          </li>
        </ul>
      </div>
    </section>

    <section>
      <h2 class="title">Reporting Mechanism</h2>
      <p>
        At Superlive, the safety and well-being of children are our top
        priorities. We have a zerotolerance policy toward any form of abuse,
        exploitation, or sexualization involving minors. Violations of this
        policy may result in account removal and reporting to law enforcement or
        child protection agencies.
      </p>
      <div class="section-sub">
        <ul class="content-list">
          <li>Superlive is intended for users aged 18 years and older.</li>
          <li>
            Any attempt to falsify age to access the platform is strictly
            prohibited and may result in account suspension or removal.
          </li>
        </ul>
      </div>
    </section>

    <section>
      <h2 class="title">Enforcement and Transparency</h2>
      <div class="section-sub">
        <h2 class="title">Enforcement Measures</h2>
        <ul class="content-list">
          <li>
            Violations of the Child Safety Policy will result in immediate
            penalties, including account suspension or removal.
          </li>
          <li>
            In severe cases, Superlive will cooperate with law enforcement
            agencies during investigations.
          </li>
        </ul>
      </div>
      <div class="section-sub">
        <h2 class="title">User Education</h2>
        <p>
          We are committed to educating our community about child safety by:
        </p>
        <ul class="content-list">
          <li>Providing clear guidelines on our platform.</li>
          <li>
            Offering prompts and reminders during content creation to avoid
            risky behavior.
          </li>
        </ul>
      </div>
    </section>

    <section>
      <h2 class="title">Commitment to Child Safety</h2>
      <p>
        Superlive continually evaluates and updates its policies, systems, and
        practices to ensure the highest standards of child safety. We strive to
        create a secure and inclusive space for everyone.
      </p>
    </section>

    <section>
      <h2 class="title">Additional Resources</h2>
      <p>
        If you or someone you know encounters child exploitation or abuse
        outside of Superlive, we recommend contacting:
      </p>
      <div class="section-sub">
        <ul class="content-list">
          <li>
            <b>North America, Australia, New Zealand: </b>
            <a href="https://www.missingkids.org/home ">
              National Center for Missing & Exploited Children (NCMEC)
            </a>
          </li>
          <li>
            <b>Europe: </b>
            <a
              href="https://www.europol.europa.eu/report-a-crime/law-enforcement-reporting-channels-child-sexual-coercion-and-extortion"
            >
              Law Enforcement Reporting Channels for Child Sexual Coercion and
              Extortion
            </a>
          </li>

          <li>
            <b>Other Locations: </b>
            <a href="https://www.icmec.org">
              International Centre for Missing & Exploited Children
            </a>
          </li>
        </ul>
      </div>
      <p>
        If you are in immediate danger, contact your local emergency services.
      </p>
    </section>
  </div>
</template>

<script>
export default {
  name: "ChildSafety",

  data() {},

  components: {},

  computed: {},

  methods: {},
};
</script>
<style lang="scss" scoped>
@import "ChildSafety.scss";
</style>
