<template>
  <v-dialog v-model="showReportDialog" max-width="360">
    <v-card class="report-card">
      <v-card-text class="report-card-text">
        <v-list>
          <v-list-item
            v-for="option in reportOptions"
            :key="option"
            @click="handleReport(option)"
            link
            class="report-item"
          >
            <v-list-item-title>{{ $t(option) }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: "ReportDialog",
  data() {
    return {
      reportOptions: [
        "livestream.report_options.nudity",
        "livestream.report_options.hate",
        "livestream.report_options.inappropriate",
        "livestream.report_options.underage",
        "livestream.report_options.fake",
        "livestream.report_options.other",
        "livestream.report_options.cancel",
      ],
    };
  },
  computed: {
    showReportDialog: {
      get() {
        return this.$store.state.live.showReportDialog;
      },
      set(value) {
        this.$store.commit("live/setShowReportDialog", value);
      },
    },
  },
  methods: {
    ...mapActions("live", ["setShowReportDialog"]),

    handleReport(option) {
      if (option === "livestream.report_options.cancel") {
        this.setShowReportDialog(false);
        return;
      }

      this.$toast.success(this.$t("livestream.report_success"));

      // Close dialog after reporting
      this.setShowReportDialog(false);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "ReportDialog.scss";
</style>
