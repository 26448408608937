<!--  -->
<template>
  <div class="social-awareness">
    <div class="row-content">
      <h2 class="title">Overview</h2>
      <p class="content">
        You can meet connect with new people on the SUPERLIVE app but our app
        works best when everyone is respectful of other community members and
        understands how their behavior affects other users.
      </p>
    </div>
    <dl class="switch-list">
      <dt @click="switchContent($event)">Online Safety for Women</dt>
      <dd>
        <p>
          Compared to men, women are more likely to deepen their relationships
          online. Unfortunately, women between 18-24 are more likely to be
          exposed to severe harassment at much higher rates than any other
          demographic.
        </p>
        <p>
          In addition to following our
          <router-link to="/safety/safety-tips">Tips for Safety</router-link>,
          we urge that women practice extra caution when using an online social
          app. While women should feel empowered to use SUPERLIVE as a social
          app, you should take extra care when it comes to sharing information.
          Avoid any users who make you feel uncomfortable and block those
          community members in the settings.
        </p>
        <p>
          We strongly advise that women report any users who they think are
          specifically targeting and harassing women. If we find that user fails
          to follow our community guidelines, then the offender might be banned
          from our app.
        </p>
        <p>
          Specific helpline numbers exist in many countries that women can call
          if they are confronted with verbal or physical harassment. For more
          specific help with an issue, we encourage women to contact their
          government or national women's helpline for further advice and help.
        </p>
        <p>Useful references</p>
        <p>
          •
          <a href="https://www.womensaid.org.uk/" target="_self"
            >Women’s Aid (UK)</a
          ><br />•
          <a href="https://www.womenagainstabuse.org/" target="_self"
            >Women Against Abuse (US)</a
          ><br />•
          <a
            href="https://ec.europa.eu/justice/saynostopvaw/helpline.html"
            target="_self"
            >European Women’s Helpline Directory (EU)</a
          >
        </p>
      </dd>
    </dl>
    <dl class="switch-list">
      <dt @click="switchContent($event)">Nudity and Sexting</dt>
      <dd>
        <p>
          Certain acts such as flirting, conveying sexual desires and
          establishing relationships occur increasingly online.
        </p>
        <p>
          Some users might think that sharing nudes (often referred to as
          sexting) will catch other people's attention or make them more popular
          in the community.
        </p>
        <p>
          In certain situations, users might feel pressured by their romantic
          partners, friends or complete strangers to share nudes without
          understanding that sharing these images could put them at danger of
          sexual exploitation.
        </p>
        <p>
          These users might find that the content they shared such as photos or
          videos are shared with other people without their consent. This is
          often referred to as revenge porn or sextortion.
        </p>
        <p>
          Separately, by violating our
          <router-link to="/safety/community-guidelines"
            >Community Guidelines</router-link
          >, some users might try to expose themselves voluntarily without the
          consent of other users.
        </p>
        <p>
          Absolutely no nudes or sexual images are allowed during the matching
          phase in the SUPERLIVE app. To protect our users, we've implemented
          specific artificial intelligence technology in addition to human
          reporting tools to detect and terminate the streaming session as
          quickly as possible. <br /><router-link to="/safety/safety-tools"
            >Find out more about our safety technology.</router-link
          >
        </p>
      </dd>
    </dl>
    <dl class="switch-list">
      <dt @click="switchContent($event)">No Underage Users</dt>
      <dd>
        <p>
          The SUPERLIVE app is a platform dedicated only to adults over the age
          of 18. Anyone under 18 is strictly forbidden from signing up.
        </p>
        <p>
          Parents who see their children using the SUPERLIVE app should inform
          us by submitting a report and sending us a message. We will
          immediately close the account.
        </p>
        <p>
          Useful websites<br />
          •
          <a href="https://www.iwf.org.uk/" target="_self"
            >Internet Watch Foundation (UK)</a
          ><br />
          •
          <a href="https://www.childnet.com/" target="_self"
            >Childnet International (INT)</a
          >
        </p>
      </dd>
    </dl>
    <dl class="switch-list">
      <dt @click="switchContent($event)">Discrimination</dt>
      <dd>
        <p>
          We maintain a zero-tolerance policy for any behavior that we deem
          discriminatory. Members of the community who discriminate another user
          based on race, age, gender identity, disability, marital status,
          nationality, sex, sexual orientation or any other characteristic
          protected under international and local law could be sent a warning
          and have their accounts removed.
        </p>
        <p>
          While we are unable to control all aspects of your experience on the
          SUPERLIVE app, please contact us immediately if another community
          member is discriminating against you in away you think is
          unacceptable. Once your report is sent to our team, we will be able to
          investigate it further.
        </p>
      </dd>
    </dl>
    <dl class="switch-list">
      <dt @click="switchContent($event)">Fake Profiles and Scams</dt>
      <dd>
        <p>
          Users on social networks sometimes pretend to be someone they are not
          with fake profiles in order to fool other people to become friends
          with them for the wrong reasons.
        </p>
        <p>
          Catfishing and other online scams can be concerning for users and can
          put them in danger of identity theft and sexual exploitation.
        </p>
        <p>
          It's our mission to know the real identity of everyone in our
          community as we are of the belief it makes the SUPERLIVE app community
          a safer space. We remove fake profiles and handle scams as soon as we
          know about them.
        </p>
        <p>Useful websites</p>
        <p>
          •
          <a href="https://www.esafety.gov.au/" target="_self"
            >Office of the eSafety Commissioner (AU)</a
          ><br />
          •
          <a href="https://www.getcybersafe.gc.ca/index-eng.aspx" target="_self"
            >Get Cyber Safe (CA)</a
          ><br />
          •
          <a href="https://www.internetmatters.org/" target="_self"
            >Internet Matters (US)</a
          ><br />
          •
          <a href="https://www.getsafeonline.org/privacy-policy/" target="_self"
            >Get Safe Online (US)</a
          ><br />
          •
          <a href="https://www.connectsafely.org/" target="_self"
            >ConnectSafely (US)</a
          ><br />
          • <a href="https://www.netsafe.org.nz/" target="_self">Netsafe (NZ)</a
          ><br />
        </p>
      </dd>
    </dl>
    <dl class="switch-list">
      <dt @click="switchContent($event)">Harmful & Illegal Content</dt>
      <dd>
        <p>
          Violence, drugs, racism, homophobia, violent extremism, pornography,
          gambling, criminal activity, can all encountered online. This kind of
          content could be inaccurate, offensive or even illegal.
        </p>
        <p>
          Harmful and illegal content is not tolerated on SUPERLIVE app and is
          removed as soon as we become aware of it. If the content could be
          considered illegal, we refer it to law enforcement.
        </p>
      </dd>
    </dl>
    <dl class="switch-list">
      <dt @click="switchContent($event)">Offensive Language</dt>
      <dd>
        <p>
          Users sometimes test boundaries with the language used on the
          internet, either by swearing on chats or using emojis that have sexual
          connotations.
        </p>
        <p>
          SUPERLIVE app utilizes an advanced algorithm that automatically
          monitors certain inappropriate words sent by users when chatting,
          taking action when appropriate.
        </p>
        <p>
          If a user uses a blacklisted word during the matching phase, the
          message which contained that word will not be sent to the receiving
          party (backlisted words include those with sexual or inappropriate
          connotations).
        </p>
      </dd>
    </dl>
    <dl class="switch-list">
      <dt @click="switchContent($event)">Self-esteem</dt>
      <dd>
        <p>
          Any internet user may experience body shaming, bullying and other
          kinds of humiliation online.
        </p>
        <p>
          In this age of photoshop and filters, there’s a lot of pressure on
          internet users to look and behave a certain way. Be aware of the
          effects social media may have on you or your loved ones’ mental
          health.
        </p>
        <p>
          Useful links:<br />
          •
          <a href="https://youngminds.org.uk/" target="_self"
            >Young Minds | Useful Tips (UK)</a
          >
        </p>
        <br />
        •
        <a href="https://www.mind.org.hk/" target="_self"
          >Mind | Mental Health Organisation (HK)</a
        >
      </dd>
    </dl>
  </div>
</template>

<script>
export default {
  name: "online-social-awareness",
  data() {
    return {};
  },
  methods: {
    switchContent(e) {
      const el = e.target;
      el.className === "on" ? (el.className = "") : (el.className = "on");
    },
  },
};
</script>
<style lang="css" scoped>
@media (min-width: 768px) {
  .social-awareness {
    width: 1000px;
    margin: 0 auto;
    padding-bottom: 100px;
  }
  .social-awareness .row-content {
    width: 970px;
    padding-top: 140px;
    margin: 0px auto 90px auto;
  }
  .social-awareness .row-content .title {
    font-size: 40px;
    line-height: 47px;
    font-weight: bold;
    color: #333;
    margin-bottom: 25px;
  }
  @media (prefers-color-scheme: dark) {
    .social-awareness .row-content .title {
      color: #cccccc;
    }
    .social-awareness {
      background-color: #000;
    }
  }
  .social-awareness .row-content .content {
    color: #6b6b6b;
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 15px;
  }
  @media (prefers-color-scheme: dark) {
    .social-awareness .row-content .content {
      color: #949494;
    }
  }
  .social-awareness .switch-list {
    margin-bottom: 20px;
  }
  .social-awareness .switch-list > dt {
    width: 100%;
    height: 60px;
    background-color: #fafafa;
    color: #333;
    font-size: 24px;
    font-weight: bold;
    padding: 0 20px;
    line-height: 60px;
    position: relative;
    text-align: left;
  }
  @media (prefers-color-scheme: dark) {
    .social-awareness .switch-list > dt {
      color: #cccccc;
      background-color: #0f0f0f;
    }
  }
  .social-awareness .switch-list > dt::after {
    content: "";
    width: 32px;
    height: 32px;
    position: absolute;
    top: 15px;
    right: 20px;
    background: url("../../../assets/img/safety/xia.png") no-repeat;
    transition: all 0.3s linear;
  }
  .social-awareness .switch-list > dt.on::after {
    transform: rotate(180deg);
  }
  .social-awareness .switch-list > dt.on + dd {
    display: block;
  }
  .social-awareness .switch-list > dd {
    font-size: 24px;
    color: #5e5e5e;
    line-height: 28px;
    text-align: left;
    display: none;
    padding-bottom: 15px;
  }
  @media (prefers-color-scheme: dark) {
    .social-awareness .switch-list > dd {
      color: #a1a1a1;
    }
  }
  .social-awareness .switch-list > dd p {
    margin-top: 25px;
  }
}
@media (max-width: 768px) {
  .social-awareness {
    padding: 10px 25px;
  }
  .social-awareness .row-content {
    padding: 20px 0 30px 0;
  }
  .social-awareness .row-content .title {
    font-size: 22px;
    line-height: 26px;
    font-weight: bold;
    color: #333;
    margin-bottom: 18px;
  }
  @media (prefers-color-scheme: dark) {
    .social-awareness .row-content .title {
      color: #cccccc;
    }
  }
  .social-awareness .row-content .content {
    color: #6b6b6b;
    font-size: 14px;
    line-height: 18px;
    margin-bottom: 15px;
  }
  @media (prefers-color-scheme: dark) {
    .social-awareness .row-content .content {
      color: #949494;
    }
  }
  .social-awareness .switch-list {
    margin-bottom: 10px;
  }
  .social-awareness .switch-list > dt {
    width: 100%;
    height: 32px;
    background-color: #fafafa;
    color: #333;
    font-size: 14px;
    font-weight: bold;
    padding: 0 15px;
    line-height: 32px;
    position: relative;
    text-align: left;
  }
  @media (prefers-color-scheme: dark) {
    .social-awareness .switch-list > dt {
      color: #cccccc;
      background-color: #0f0f0f;
    }
  }
  .social-awareness .switch-list > dt::after {
    content: "";
    width: 16px;
    height: 16px;
    position: absolute;
    top: 9px;
    right: 15px;
    background: url("../../../assets/img/safety/xia.png") no-repeat;
    background-size: 100%;
    transition: all 0.3s linear;
  }
  .social-awareness .switch-list > dt.on::after {
    transform: rotate(180deg);
  }
  .social-awareness .switch-list > dt.on + dd {
    display: block;
  }
  .social-awareness .switch-list > dd {
    font-size: 14px;
    color: #5e5e5e;
    line-height: 18px;
    text-align: left;
    display: none;
    padding-bottom: 15px;
  }
  @media (prefers-color-scheme: dark) {
    .social-awareness .switch-list > dd {
      color: #a1a1a1;
    }
  }
  .social-awareness .switch-list > dd p {
    margin-top: 17px;
  }
}
</style>
