<!--  -->
import { clearInterval } from 'timers';
<template>
  <div class="switch-img">
    <img
      v-for="(item, index) in images"
      :class="{ show: index === currentIndex }"
      :src="item"
      :key="index"
      alt="imgaes"
    />
  </div>
</template>

<script>
export default {
  name: "switch-img",
  data() {
    return {
      currentIndex: 0,
      timer: null,
    };
  },
  props: {
    images: {
      type: Array,
      default: () => [],
    },
    time: {
      type: Number,
      default: 2,
    },
  },
  mounted() {
    if (this.images.length > 1) {
      this.timer = window.setInterval(this.imgSwitch, this.time * 1000);
    }
  },
  destroyed() {
    window.clearInterval(this.timer);
  },
  methods: {
    imgSwitch() {
      let len = this.images.length - 1;
      if (this.currentIndex >= len) {
        this.currentIndex = 0;
      } else {
        this.currentIndex++;
      }
    },
  },
};
</script>
<style lang="css" scoped>
.switch-img {
  width: 100%;
}
.switch-img img {
  display: none;
  max-width: 100%;
}
.switch-img .show {
  display: block;
}
</style>
