<!--  -->
<template>
  <div class="make-a-report">
    <div class="row-line">
      <h2 class="title">Overview</h2>
      <p class="content">
        1. Click 'make a report'<br />
        2. Enter your username and country<br />
        3. In as much detail as possible, write your concern or about the
        incident
      </p>
      <p class="content">
        We prioritize reports regarding any type of physical risk, but we are
        responsive to all reports within 20 business days.
      </p>
    </div>
    <div class="row-line">
      <h2 class="title">In-App Reporting</h2>
      <p class="content">
        Reporting through our app is fast and easy. To report someone, simply
        click on the shield symbol when matching or after a user is on your
        friends list. You'll then see a list of reasons for making the report.
      </p>
    </div>
    <div class="row-line">
      <h2 class="title">Do you need advice about using the SUPERLIVE app?</h2>
      <p class="content">
        Contact our team of experts if you need help on using the app or advice
        on the best way to use the SUPERLIVE app. They will be happy to provide
        all the support you need. Social media can sometimes be difficult to
        handle
        <b>but we're here to help!</b>
      </p>
      <div class="row-line-button">
        <a
          :href="'mailto:help@superlivellc.com?subject=SUPERLIVE Safety Advice'"
          >Get Advice</a
        >
      </div>
    </div>
    <div class="row-line">
      <h2 class="title">Concerned about another user's behavior?</h2>
      <p class="content">
        If you're not happy with something that happened to you or someone close
        to you on the SUPERLIVE app, then please do let us know about it. Our
        Community Guidelines encompass issues such as nudity, bullying and fake
        profiles. We take action against users who fail to follow these
        guidelines.
      </p>
      <div class="row-line-button">
        <a
          :href="'mailto:help@superlivellc.com?subject=SUPERLIVE Safety Report'"
          >Report a Concern</a
        >
      </div>
    </div>
    <div class="row-line">
      <h2 class="title">
        Are you or someone else you know in physical danger?
      </h2>
      <p class="content">
        If something occurred that has put a SUPERLIVE app user in immediate
        danger - such as threats of violence or suicide - immediately report it
        to us and contact the police. We take emergency reports seriously and
        collaborate with the police and/or other local authorities in taking
        action.
      </p>
      <div class="row-line-button">
        <a :href="'mailto:help@superlivellc.com?subject=SUPERLIVE Safety'"
          >Report an Emergency</a
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "make-a-report",
  data() {
    return {
      domain: location.host,
    };
  },

  components: {},

  computed: {},

  methods: {},
};
</script>
<style lang="css" scoped>
@media (min-width: 768px) {
  .make-a-report {
    width: 1000px;
    margin: 0 auto;
    padding-top: 140px;
    padding-bottom: 40px;
  }
  .make-a-report .row-line {
    margin-bottom: 40px;
    text-align: left;
  }
  .make-a-report .row-line .title {
    font-size: 40px;
    font-weight: bold;
    color: #333;
    margin-bottom: 25px;
  }
  @media (prefers-color-scheme: dark) {
    .make-a-report .row-line .title {
      color: #cccccc;
    }
  }
  .make-a-report .row-line .content {
    font-size: 24px;
    color: #5e5e5e;
    margin-bottom: 20px;
    line-height: 28px;
  }
  @media (prefers-color-scheme: dark) {
    .make-a-report .row-line .content {
      color: #a1a1a1;
    }
  }
  .make-a-report .row-line .row-line-button {
    text-align: right;
    margin-top: 20px;
  }
  .make-a-report .row-line .row-line-button > a {
    display: inline-block;
    width: 282px;
    height: 60px;
    background-color: #0386e8;
    color: #fff;
    text-align: center;
    line-height: 60px;
    font-size: 24px;
    border-radius: 8px;
    text-decoration: none;
  }
  .make-a-report .red {
    color: #f94a27;
  }
}
@media (max-width: 768px) {
  .make-a-report {
    padding: 35px 25px;
    padding-bottom: 40px;
  }
  .make-a-report .row-line {
    margin-bottom: 30px;
    text-align: left;
  }
  .make-a-report .row-line .title {
    font-size: 22px;
    font-weight: bold;
    color: #333;
    margin-bottom: 15px;
  }
  @media (prefers-color-scheme: dark) {
    .make-a-report .row-line .title {
      color: #cccccc;
    }
  }
  .make-a-report .row-line .content {
    font-size: 14px;
    color: #5e5e5e;
    margin-bottom: 10px;
    line-height: 18px;
  }
  @media (prefers-color-scheme: dark) {
    .make-a-report .row-line .content {
      color: #a1a1a1;
    }
  }
  .make-a-report .row-line .row-line-button {
    text-align: right;
    margin-top: 20px;
  }
  .make-a-report .row-line .row-line-button > a {
    display: inline-block;
    width: 100%;
    height: 35;
    background-color: #0386e8;
    color: #fff;
    text-align: center;
    line-height: 35px;
    font-size: 18px;
    border-radius: 5px;
    text-decoration: none;
  }
  .make-a-report .red {
    color: #f94a27;
  }
}
</style>
