<template>
  <div id="safetyApp">
    <top-nav></top-nav>
    <div class="main-body">
      <keep-alive>
        <router-view />
      </keep-alive>
    </div>
    <footer-nav></footer-nav>
  </div>
</template>
<script>
import Top from "./components/top";
export default {
  components: {
    "top-nav": Top,
  },
};
</script>
<style lang="css">
@media (max-width: 768px) {
  .main-body {
    min-height: calc(100vh - 200px);
  }
}
@media (prefers-color-scheme: dark) {
  .main-body {
    background-color: #000;
  }
}

ol,
ul {
  list-style: none;
}

a,
abbr,
acronym,
address,
applet,
article,
aside,
audio,
b,
big,
blockquote,
body,
canvas,
caption,
center,
cite,
code,
dd,
del,
details,
dfn,
div,
dl,
dt,
em,
embed,
fieldset,
figcaption,
figure,
footer,
form,
h1,
h2,
h3,
h4,
h5,
h6,
header,
hgroup,
html,
i,
iframe,
img,
ins,
kbd,
label,
legend,
li,
mark,
menu,
nav,
object,
ol,
output,
p,
pre,
q,
ruby,
s,
samp,
section,
small,
span,
strike,
strong,
sub,
summary,
sup,
table,
tbody,
td,
tfoot,
th,
thead,
time,
tr,
tt,
u,
ul,
var,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  line-height: normal;
  font-family: inherit;
  color: inherit;
  vertical-align: baseline;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

a {
  color: #0386e8;
  text-decoration: underline;
}
</style>
