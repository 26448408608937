<!--  -->
<template>
  <div class="faqs">
    <div class="tab-fix">
      <ul class="tab-box">
        <li
          v-for="item in navData"
          :key="item.id"
          @click="current = item.id"
          :class="{ active: item.id === current }"
        >
          {{ item.name }}
        </li>
      </ul>
    </div>
    <div class="row-line" v-show="current === 'tab-1'">
      <h2 class="title">General</h2>
      <p class="fu-title">
        • Can I join the SUPERLIVE if I'm under 18 years old?
      </p>
      <p class="content">
        No, this platform is only for people over 18. If you see a user under
        the age of 18, contact us immediately by clicking on this
        <a
          :href="'mailto:help@superlivellc.com?subject=SUPERLIVE Safety Report'"
          >link</a
        >, or by reporting them in the app itself. Please visit
        <router-link to="/safety/community-guidelines"
          >our community guidelines</router-link
        >
        for more info on our age policy.
      </p>
      <p class="fu-title">• Is the SUPERLIVE app free to use?</p>
      <p class="content">
        Yes, the SUPERLIVE app is free to download and begin matching. However,
        there are extra features that are not free. You can use these services
        by buying coins in the SUPERLIVE app.
      </p>
      <p class="fu-title">
        • I've never used the SUPERLIVE app before - why did I get an email from
        you?
      </p>
      <p class="content">
        Most likely, someone has attempted to sign up for a SUPERLIVE app
        account by mistakenly using your email address. To unsubscribe from our
        emails:<br />
        1. Open any e-mail from SUPERLIVE app.<br />
        2. Scroll to the bottom of the e-mail and click "Unsubscribe."<br />
        Upon clicking the Unsubscribe button, you will stop receiving any emails
        from SUPERLIVE app.
      </p>
    </div>
    <div class="row-line" v-show="current === 'tab-2'">
      <h2 class="title">My Account</h2>
      <p class="fu-title">
        • My account was blocked for inappropriate behavior, but I did not do
        anything to violate the Community Guidelines. How can I unblock my
        account?
      </p>
      <p class="content">
        Please get in touch with us by clicking on this
        <a
          :href="'mailto:help@superlivellc.com?subject=SUPERLIVE Account Recovery'"
          >link</a
        >
        and our team will get back to you as quickly as possible regarding your
        account. We highly recommend that all community members look at the
        <router-link to="/safety/social-awareness"
          >Online Social Awareness page</router-link
        >, read our
        <router-link to="/safety/community-guidelines"
          >Community Guidelines</router-link
        >
        and check out our
        <router-link to="/safety/make-report">Make a Report</router-link> to
        quickly get in touch with our team.
      </p>
      <p class="fu-title">
        • Is the SUPERLIVE app sharing my exact location with other users?
      </p>
      <p class="content">
        No, the SUPERLIVE app does not share the exact location of its users.
        The app only shares the country where the user is located.
      </p>
      <p class="fu-title">• Why can’t I make another account?</p>
      <p class="content">
        There can only be one account registered to a sigle device. If you've
        received a notification that you have multiple accounts, then please use
        a previously registered account.
      </p>
      <p class="fu-title">• How can I delete my account?</p>
      <p class="content">
        Login to your account and go to your profile. Open settings and select
        “Help and Feedback”, then select “Delete Account”.
      </p>
    </div>
    <!-- <div class="row-line" v-show="current === 'tab-3'">
      <h2 class="title">Using SUPERLIVE app</h2>
      <p class="fu-title">• How do I buy coins?</p>
      <p class="content">Click the “Buy Coins” button in the app on your profile page and you will be able to recharge.</p>
      <p class="fu-title">• Why didn’t I receive coins after recharging?</p>
      <p class="content">oPlease restart or re-login to the app<br>oCheck your internet connection and whether the money was deducted successfully.<br>oIf the above 2 steps don’t work, please contact our customer service by going to the messaging panel in the app or <a :href="'mailto:safety@'+domain+'?subject=SUPERLIVE Safety Request'">contact us on Email.</a></p>
      <p class="fu-title">• Why can’t I buy coins?</p>
      <p class="content">oCheck your internet connection and update the app to the latest version.<br>oRestart your device and re-login to the app.<br>oContact Google Play or Apple Store to check if your account is activated.<br>oIf the above 3 steps don’t work, please contact our customer service by going to the messaging panel in the app or <a :href="'mailto:safety@'+domain+'?subject=SUPERLIVE Safety Request'">contact us on Email.</a></p>
      <p class="fu-title">• Why am I matching with people in other countries?</p>
      <p class="content">SUPERLIVE app is a global platform, meaning you can match with people around the world at any time. It is not possible to limit your matching area to just your country, however, this is a feature we are working on – when it’s ready we’ll let you know.</p>
      <p class="fu-title">• Why do I keep getting disconnected?</p>
      <p class="content">Please make sure you have a stable internet connection and if it continues to happen, try restarting your device. Our engineers are always working hard to make sure every user has the best experience possible, if you are still encountering an error please reach out to customer service in the SUPERLIVE app app.</p>
    </div> -->
    <div class="row-line" v-show="current === 'tab-4'">
      <h2 class="title">Safety & Privacy</h2>
      <p class="fu-title">• What if another user makes me uncomfortable?</p>
      <p class="content">
        Block the user, and if in doubt, contact us and our safety team will
        respond ASAP. Hateful and harmful content, nudity and sexually explicit
        behaviors are not tolerated on our app during the matching phase or when
        talking to streamers. Please visit
        <router-link to="/safety/social-awareness">
          our Online Social Awareness page</router-link
        >, read our
        <router-link to="/safety/community-guidelines"
          >Community Guidelines</router-link
        >
        and visit our
        <router-link to="/safety/make-report">Make a Report page</router-link>
        to get in touch with us
      </p>
      <p class="fu-title">
        • I received inappropriate content while using the app and want to
        report the user.
      </p>
      <p class="content">
        Contact us immediately by clicking on this link and our safety team will
        respond ASAP. Hateful and harmful content, nudity and sexually explicit
        behaviors are not tolerated on our app during the matching phase or when
        talking to streamers. Please visit our
        <router-link to="/safety/social-awareness"
          >our Online Social Awareness page</router-link
        >, read our
        <router-link to="/safety/community-guidelines"
          >Community Guidelines</router-link
        >
        and visit our
        <router-link to="/safety/make-report">Make a Report page</router-link>
        to get in touch with us.
      </p>
      <p class="fu-title">• How do I report fake profiles or scams?</p>
      <p class="content">
        Contact us immediately by clicking on this link and our safety team will
        respond ASAP. Hateful and harmful content, nudity and sexually explicit
        behaviors are not tolerated on our app during the matching phase or when
        talking to streamers. Please visit our
        <router-link to="/safety/social-awareness"
          >our Online Social Awareness page</router-link
        >, read our
        <router-link to="/safety/community-guidelines"
          >Community Guidelines</router-link
        >
        and visit our
        <router-link to="/safety/make-report">Make a Report page</router-link>
        to get in touch with us.
      </p>
      <p class="fu-title">• How can I block someone?</p>
      <p class="content">
        It's easy to block people on the SUPERLIVE app. Please refer to
        <router-link to="/safety/safety-tools">our safety tools</router-link> to
        learn how to block someone and manage your blacklist.
      </p>
      <p class="fu-title">
        • Is it possible to share contact information with other users on
        SUPERLIVE app?
      </p>
      <p class="content">
        It is best to keep your conversations on the SUPERLIVE app platform
        while you're still getting to know someone. Users with bad intentions
        often try to move the conversation to text, messaging apps, email or
        phone immediately.
      </p>
      <p class="fu-title">
        • Is it possible to share private information on SUPERLIVE app?
      </p>
      <p class="content">
        Never share personal information, such as your social security number,
        home or work address or details about your day-to-day routine with
        strangers online.
      </p>
      <p class="fu-title">
        • Is it safe to share my financial information on SUPERLIVE app?
      </p>
      <p class="content">
        Never share information that could be used to access your financial
        accounts. Contact us immediately if another user asks you for money.
      </p>
      <p class="fu-title">
        • Is it possible to send other users money on SUPERLIVE app?
      </p>
      <p class="content">
        Never send money online, especially via wire transfer, even if the
        person claims to be in an emergency. Wiring money is like sending cash —
        it’s practically impossible to reverse the transaction or track where
        the money goes.
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "faqs",
  data() {
    return {
      domain: location.host,
      current: "tab-4",
      navData: [
        {
          name: "General",
          id: "tab-1",
        },
        {
          name: "My Account",
          id: "tab-2",
        },
        // {
        //   name: 'Using SUPERLIVE app',
        //   id: 'tab-3'
        // },
        {
          name: "Safety & Privacy",
          id: "tab-4",
        },
      ],
    };
  },
  methods: {},
};
</script>
<style lang="css" scoped>
@media (min-width: 1100px) {
  .faqs {
    width: 1000px;
    min-height: 100vh;
    margin: 0 auto;
    padding-top: 160px;
    padding-bottom: 40px;
  }
  .faqs .tab-fix {
    position: fixed;
    left: 100px;
    top: 100px;
    right: 0;
    height: 50px;
    background-color: #ffffff;
  }
  @media (prefers-color-scheme: dark) {
    .faqs .tab-fix {
      background-color: black;
    }
  }
  .faqs .tab-box {
    width: 1000px;
    height: 100%;
    margin: 0 auto;
    display: flex;
    align-items: center;
  }
  .faqs .tab-box li {
    margin-right: 30px;
    text-align: center;
    cursor: pointer;
    font-size: 24px;
    font-weight: bold;
    color: #333;
    position: relative;
  }
  @media (prefers-color-scheme: dark) {
    .faqs .tab-box li {
      color: #cccccc;
    }
  }
  .faqs .tab-box li.active::before {
    content: "";
    position: absolute;
    left: 50%;
    bottom: -10px;
    width: 38px;
    height: 3px;
    background-color: #0386e8;
    transform: translateX(-50%);
  }
  .faqs .row-line {
    margin-bottom: 40px;
    text-align: left;
  }
  .faqs .row-line .title {
    font-size: 40px;
    font-weight: bold;
    color: #333;
    margin-bottom: 25px;
  }
  @media (prefers-color-scheme: dark) {
    .faqs .row-line .title {
      color: #cccccc;
    }
  }
  .faqs .row-line .fu-title {
    color: #333;
    font-size: 24px;
    margin-bottom: 10px;
    font-weight: bold;
  }
  @media (prefers-color-scheme: dark) {
    .faqs .row-line .fu-title {
      color: #cccccc;
    }
  }
  .faqs .row-line .content {
    font-size: 24px;
    color: #5e5e5e;
    margin-bottom: 20px;
    line-height: 28px;
  }
  @media (prefers-color-scheme: dark) {
    .faqs .row-line .content {
      color: #a1a1a1;
    }
  }
  .faqs .row-line .row-line-button {
    text-align: right;
    margin-top: 20px;
  }
  .faqs .row-line .row-line-button > a {
    display: inline-block;
    width: 282px;
    height: 60px;
    background-color: #0386e8;
    color: #fff;
    text-align: center;
    line-height: 60px;
    font-size: 24px;
    border-radius: 8px;
    text-decoration: none;
  }
}
@media (max-width: 1100px) {
  .tab-fix {
    width: 100%;
  }
}
@media (max-width: 768px) {
  .tab-fix {
    width: 100%;
    padding: 25px;
    background-color: #ffffff;
  }
  @media (prefers-color-scheme: dark) {
    .faqs .tab-fix {
      background-color: black;
    }
  }

  .faqs {
    padding: 0px 25px 10px 25px;
  }
  .faqs .tab-box {
    display: flex;
  }
  .faqs .tab-box li {
    margin-right: 18px;
    text-align: center;
    cursor: pointer;
    font-size: 12px;
    font-weight: bold;
    color: #333;
    position: relative;
  }
  @media (prefers-color-scheme: dark) {
    .faqs .tab-box li {
      color: #cccccc;
    }
  }
  .faqs .tab-box li.active::before {
    content: "";
    position: absolute;
    left: 50%;
    bottom: -10px;
    width: 38px;
    height: 3px;
    background-color: #0386e8;
    transform: translateX(-50%);
  }
  .faqs .row-line {
    margin-bottom: 40px;
    text-align: left;
  }
  .faqs .row-line .title {
    font-size: 22px;
    font-weight: bold;
    color: #333;
    margin-bottom: 13px;
  }
  @media (prefers-color-scheme: dark) {
    .faqs .row-line .title {
      color: #cccccc;
    }
  }
  .faqs .row-line .fu-title {
    color: #333;
    font-size: 16px;
    margin-bottom: 10px;
    font-weight: bold;
  }
  @media (prefers-color-scheme: dark) {
    .faqs .row-line .fu-title {
      color: #cccccc;
    }
  }
  .faqs .row-line .content {
    font-size: 14px;
    color: #5e5e5e;
    margin-bottom: 10px;
    line-height: 18px;
  }
  @media (prefers-color-scheme: dark) {
    .faqs .row-line .content {
      color: #a1a1a1;
    }
  }
  .faqs .row-line .row-line-button {
    text-align: right;
    margin-top: 20px;
  }
  .faqs .row-line .row-line-button > a {
    display: inline-block;
    width: 282px;
    height: 60px;
    background-color: #0386e8;
    color: #fff;
    text-align: center;
    line-height: 60px;
    font-size: 24px;
    border-radius: 8px;
    text-decoration: none;
  }
}
</style>
