<!-- about -->
<template>
  <div class="safety-tips">
    <div class="banner">
      <div class="banner-text">
        <p class="title">Take charge of your safety</p>
        <p>
          The following tips are our suggestions of how you can best use our app
          and stay safe. Make sure to also read our
          <router-link to="/safety/community-guidelines"
            >Community Guidelines</router-link
          >.
        </p>
      </div>
      <div class="banner-img">
        <img src="../../../assets/img/safety/large-2.png" alt="" />
      </div>
    </div>
    <div class="full-row">
      <dl class="img-text-warp">
        <dt class="pc">
          <img src="../../../assets/img/safety/img2-1.png" alt="" />
        </dt>
        <dd class="margin-l">
          <p class="row-title">1. Be honest about yourself</p>
          <p class="row-p">
            When you start using the SUPERLIVE app, ensure that you're signing
            up with accurate personal information, including your correct name,
            age and profile picture. The best way to connect with other people
            on our app is by being yourself.
          </p>
        </dd>
        <dt class="mobile">
          <img src="../../../assets/img/safety/img2-1.png" alt="" />
        </dt>
      </dl>
    </div>
    <div class="full-row">
      <dl class="img-text-warp">
        <dd class="margin-r">
          <p class="row-title">
            2. Connections made on the SUPERLIVE app should remain on the
            SUPERLIVE app
          </p>
          <p class="row-p">
            Even though you might have made a new connection through the app, we
            urge that you not share other personal info such as your location,
            social media accounts and phone number with any other community
            members. We recommend that communication with new members remain in
            the app. Always be extremely careful when someone asks you for
            personal details.
          </p>
        </dd>
        <dt><img src="../../../assets/img/safety/img2-2.png" alt="" /></dt>
      </dl>
    </div>
    <div class="full-row">
      <dl class="img-text-warp">
        <dt class="pc">
          <img src="../../../assets/img/safety/img2-3.png" alt="" />
        </dt>
        <dd class="margin-l">
          <p class="row-title">3. Shield your financial information</p>
          <p class="row-p">
            Be aware that if another community member tries to get your
            financial information, then it could be a fraud or scam. Under no
            circumstances should you provide your financial information to
            another SUPERLIVE app user to protect your safety.
          </p>
        </dd>
        <dt class="mobile">
          <img src="../../../assets/img/safety/img2-3.png" alt="" />
        </dt>
      </dl>
    </div>
    <div class="full-row">
      <dl class="img-text-warp">
        <dd class="margin-r">
          <p class="row-title">4. Reveal only your face during video chat</p>
          <p class="row-p">
            Your screen will be blurred until the app detects a face or until
            you decide to un-blur the other person's video feed. Ensure that
            your face is visible on camera and disconnect with anyone who is not
            showing their face.
          </p>
        </dd>
        <dt><img src="../../../assets/img/safety/img2-4.png" alt="" /></dt>
      </dl>
    </div>
    <div class="full-row">
      <dl class="img-text-warp">
        <dt class="pc">
          <img src="../../../assets/img/safety/img2-5.png" alt="" />
        </dt>
        <dd class="margin-l">
          <p class="row-title">5. Be in a safe and secure location</p>
          <p class="row-p">
            While using the SUPERLIVE app, we suggest that you be in a secure
            and private location for your safety. If you use the app in public,
            make sure not to show any obvious landmarks, addresses, store names,
            etc. on camera so that other app users won't know your exact
            location.
          </p>
        </dd>
        <dt class="mobile">
          <img src="../../../assets/img/safety/img2-5.png" alt="" />
        </dt>
      </dl>
    </div>
    <div class="full-row">
      <dl class="img-text-warp">
        <dd class="margin-r">
          <p class="row-title">6. Show kindness and respect</p>
          <p class="row-p">
            Check out our
            <router-link to="/safety/community-guidelines"
              >Community Guidelines</router-link
            >
            to understand what it takes to be a kind and respectful member of
            the community. To keep the peace in the community, please follow our
            guidelines.
          </p>
        </dd>
        <dt><img src="../../../assets/img/safety/img2-6.png" alt="" /></dt>
      </dl>
    </div>
    <div class="full-row">
      <dl class="img-text-warp">
        <dt class="pc">
          <img src="../../../assets/img/safety/img2-7.png" alt="" />
        </dt>
        <dd class="margin-l">
          <p class="row-title">7. We want to help you!</p>
          <p class="row-p">
            If the way that anyone behaves makes you feel uncomfortable or if
            you need some useful advice, get in touch with us through the app or
            our safety center. You can easily submit a report by clicking on the
            shield symbol in the app.
          </p>
          <p class="row-link">
            <router-link to="/safety/make-report"
              >Submit a report here.</router-link
            >
          </p>
        </dd>
        <dt class="mobile">
          <img src="../../../assets/img/safety/img2-7.png" alt="" />
        </dt>
      </dl>
    </div>
    <div class="row-footer">
      <h2 class="title">Disclaimer</h2>
      <p class="content">
        Please note that these trips are not an exhaustive list of the intended
        use of the SUPERLIVE app. To find out more, please read our
        comprehensive
        <router-link to="/safety/community-guidelines"
          >Community Guidelines</router-link
        >.
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "safety-tips",
  data() {
    return {};
  },
};
</script>
<style lang="css" scoped>
@media (min-width: 768px) {
  .safety-tips .banner {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: linear-gradient(180deg, white 0%, #0386e8 100%);
    min-height: 100vh;
  }
  @media (prefers-color-scheme: dark) {
    .safety-tips .banner {
      background: linear-gradient(180deg, black 0%, #011729 100%);
    }
  }
  .safety-tips .banner-text {
    width: 999px;
    padding-top: 150px;
  }
  .safety-tips .banner-text p {
    margin-bottom: 15px;
    font-size: 24px;
    line-height: 28px;
    color: #5e5e5e;
  }
  @media (prefers-color-scheme: dark) {
    .safety-tips .banner-text p {
      color: #a1a1a1;
    }
  }
  .safety-tips .banner-text .title {
    color: #0386e8;
    font-size: 40px;
    font-weight: bold;
    text-align: center;
    margin-bottom: 40px;
  }
  .safety-tips .banner .banner-img {
    max-width: 999px;
  }
  .safety-tips .full-row {
    width: 100%;
    padding: 80px 0;
  }
  .safety-tips .full-row img {
    width: auto;
    max-width: inherit;
    height: inherit;
  }
  .safety-tips .full-row:nth-child(odd) {
    background-color: #fafafa;
  }
  @media (prefers-color-scheme: dark) {
    .safety-tips .full-row:nth-child(odd) {
      background-color: #0f0f0f;
    }
  }
  .safety-tips .full-row .img-text-warp {
    width: 990px;
    margin: 0 auto;
    display: flex;
    align-items: center;
  }
  .safety-tips .full-row .img-text-warp dt {
    width: 408px;
    height: 381px;
  }
  .safety-tips .full-row .img-text-warp dd {
    text-align: left;
  }
  .safety-tips .full-row .img-text-warp .row-title {
    font-size: 40px;
    line-height: 47px;
    font-weight: bold;
    color: #333;
    margin-bottom: 25px;
  }
  @media (prefers-color-scheme: dark) {
    .safety-tips .full-row .img-text-warp .row-title {
      color: #cccccc;
    }
  }
  .safety-tips .full-row .img-text-warp .row-p {
    color: #6b6b6b;
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 15px;
  }
  @media (prefers-color-scheme: dark) {
    .safety-tips .full-row .img-text-warp .row-p {
      color: #949494;
    }
  }
  .safety-tips .full-row .img-text-warp .row-link {
    color: #0386e8;
    font-size: 24px;
  }
  .safety-tips .row-footer {
    width: 100%;
    padding: 30px calc(50vw - 485px) 90px;
    background-color: #fafafa;
  }
  @media (prefers-color-scheme: dark) {
    .safety-tips .row-footer {
      background-color: #0f0f0f;
    }
  }
  .safety-tips .row-footer .title {
    font-size: 40px;
    line-height: 47px;
    font-weight: bold;
    color: #333;
    margin-bottom: 25px;
  }
  @media (prefers-color-scheme: dark) {
    .safety-tips .row-footer .title {
      color: #cccccc;
    }
  }
  .safety-tips .row-footer .content {
    color: #6b6b6b;
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 15px;
  }
  @media (prefers-color-scheme: dark) {
    .safety-tips .row-footer .content {
      color: #949494;
    }
  }
  .safety-tips .margin-l {
    margin-left: 40px;
  }
  .safety-tips .margin-r {
    margin-right: 40px;
  }
  .safety-tips .mobile {
    display: none;
  }
}
@media (max-width: 768px) {
  .safety-tips .banner {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: linear-gradient(180deg, white 0%, #0386e8 100%);
  }
  @media (prefers-color-scheme: dark) {
    .safety-tips .banner {
      background: linear-gradient(180deg, black 0%, #011729 100%);
    }
  }
  .safety-tips .banner-text {
    padding: 25px;
  }
  .safety-tips .banner-text p {
    margin-bottom: 10px;
    font-size: 14px;
    line-height: 18px;
    color: #5e5e5e;
  }
  @media (prefers-color-scheme: dark) {
    .safety-tips .banner-text p {
      color: #a1a1a1;
    }
  }
  .safety-tips .banner-text .title {
    color: #0386e8;
    font-size: 22px;
    font-weight: bold;
    text-align: center;
    margin-bottom: 20px;
  }
  .safety-tips .banner .banner-img {
    padding: 0 25px;
  }
  .safety-tips .full-row {
    width: 100%;
    padding: 40px 25px;
  }
  .safety-tips .full-row:nth-child(odd) {
    background-color: #fafafa;
  }
  @media (prefers-color-scheme: dark) {
    .safety-tips .full-row:nth-child(odd) {
      background-color: #0f0f0f;
    }
  }
  .safety-tips .full-row .img-text-warp dt {
    padding: 20px 30px 0 30px;
  }
  .safety-tips .full-row .img-text-warp dt img {
    max-width: 100%;
  }
  .safety-tips .full-row .img-text-warp dd {
    text-align: left;
  }
  .safety-tips .full-row .img-text-warp .row-title {
    font-size: 22px;
    line-height: 25px;
    font-weight: bold;
    color: #333;
    margin-bottom: 10px;
  }
  @media (prefers-color-scheme: dark) {
    .safety-tips .full-row .img-text-warp .row-title {
      color: #cccccc;
    }
  }
  .safety-tips .full-row .img-text-warp .row-p {
    color: #6b6b6b;
    font-size: 14px;
    line-height: 18px;
    margin-bottom: 8px;
  }
  @media (prefers-color-scheme: dark) {
    .safety-tips .full-row .img-text-warp .row-p {
      color: #949494;
    }
  }
  .safety-tips .full-row .img-text-warp .row-link {
    color: #0386e8;
    font-size: 14px;
  }
  .safety-tips .margin-l {
    margin-left: 0;
  }
  .safety-tips .margin-r {
    margin-right: 0;
  }
  .safety-tips .row-footer {
    padding: 25px 25px 50px 25px;
  }
  .safety-tips .row-footer .title {
    font-size: 22px;
    line-height: 25px;
    font-weight: bold;
    color: #333;
    margin-bottom: 10px;
  }
  @media (prefers-color-scheme: dark) {
    .safety-tips .row-footer .title {
      color: #cccccc;
    }
  }
  .safety-tips .row-footer .content {
    color: #6b6b6b;
    font-size: 14px;
    line-height: 16px;
  }
  @media (prefers-color-scheme: dark) {
    .safety-tips .row-footer .content {
      color: #949494;
    }
  }
  .safety-tips .pc {
    display: none;
  }
}
</style>
