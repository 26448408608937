<!--  -->
<template>
  <div class="law-enforcement">
    <div class="row-content">
      <h2 class="title">SUPERLIVE app's Guidelines for Legal Authorities</h2>
      <p class="content">
        Law enforcement officials seeking information from the SUPERLIVE app
        should refer to the following guidelines. These guidelines are subject
        to change without notice.
      </p>
    </div>
    <div class="row-line">
      <h2 class="title">Business Records</h2>
      <p class="content">
        As per our
        <a href="/privacy-policy/" target="_self">Privacy Policy</a> and
        <a href="/terms-of-service/" target="_self">Terms of Use</a>, we gather
        the following information when new users make a SUPERLIVE app account:
        anonymized device identifiers, age, gender and name. Users can opt in to
        provide further personal information.
      </p>
      <p class="content">
        The SUPERLIVE app might gather and store information about how members
        use and interact with our product and websites.
      </p>
    </div>
    <div class="row-line">
      <h2 class="title">Legal Process Requirements</h2>
      <p class="content">
        The SUPERLIVE app will supply responsive records to any inquiries made
        by law enforcement in alignment with our terms, policies and the
        applicable laws. The guidelines outlining cooperation with law
        enforcement agencies can be found below. Please note that these
        guidelines might not be applicable in every situation.
      </p>
      <p class="content">
        Inquiries made by authorized law enforcement should be sent from an
        official government domain to
        <a :href="'mailto:help@superlivellc.com'">help@superlivellc.com</a>.
        Generally, we ask for the following mandatory information as part of a
        law enforcement inquiry:
      </p>
      <p class="content">• A clear legal foundation for making the inquiry</p>
      <p class="content">
        Information needed and how this information could help support an
        investigation. Requests that are ambiguous or broad and that do not
        benefit an investigation may not be processed.
      </p>
      <p class="content">
        In addition to an official law enforcement domain email address, we must
        have the issuing authority name, ID of the inquiring officer or agent
        and the contact number of the responsible agent or officer.
      </p>
      <p class="content">
        Requests for information might not be processed and/or might be delayed
        without this general information. In all instances, our goal is to be
        responsive and to cooperate fully with any inquiries that align with our
        terms, policies and legal processes.
      </p>
    </div>
    <div class="row-line">
      <h2 class="title">Request Notice for Users</h2>
      <p class="content">
        In certain situations, we might notify users that a request by law
        enforcement has been made for their information before providing any
        personal data.
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "law-enforcement",
  data() {
    return {
      domain: location.host,
    };
  },

  components: {},

  computed: {},

  methods: {},
};
</script>
<style lang="css" scoped>
@media (min-width: 768px) {
  .law-enforcement {
    width: 1000px;
    margin: 0 auto;
    padding-bottom: 40px;
  }
  .law-enforcement .row-content {
    width: 970px;
    padding-top: 140px;
    padding-bottom: 25px;
  }
  .law-enforcement .row-content .title {
    font-size: 40px;
    line-height: 47px;
    font-weight: bold;
    color: #333;
    margin-bottom: 25px;
  }
  @media (prefers-color-scheme: dark) {
    .law-enforcement .row-content .title {
      color: #cccccc;
    }
  }
  .law-enforcement .row-content .content {
    color: #6b6b6b;
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 15px;
  }
  @media (prefers-color-scheme: dark) {
    .law-enforcement .row-content .content {
      color: #949494;
    }
  }
  .law-enforcement .row-line {
    margin-bottom: 40px;
    text-align: left;
  }
  .law-enforcement .row-line .title {
    font-size: 40px;
    font-weight: bold;
    color: #333;
    margin-bottom: 25px;
  }
  @media (prefers-color-scheme: dark) {
    .law-enforcement .row-line .title {
      color: #cccccc;
    }
  }
  .law-enforcement .row-line .content {
    font-size: 24px;
    color: #5e5e5e;
    margin-bottom: 20px;
    line-height: 28px;
  }
  @media (prefers-color-scheme: dark) {
    .law-enforcement .row-line .content {
      color: #a1a1a1;
    }
  }
  .law-enforcement .row-line .row-line-button {
    text-align: right;
    margin-top: 20px;
  }
  .law-enforcement .row-line .row-line-button > a {
    display: inline-block;
    width: 282px;
    height: 60px;
    background-color: #0386e8;
    color: #fff;
    text-align: center;
    line-height: 60px;
    font-size: 24px;
    border-radius: 8px;
    text-decoration: none;
  }
  .law-enforcement .red {
    color: #f94a27;
  }
}
@media (max-width: 768px) {
  .law-enforcement {
    padding: 40px 25px 40px 25px;
  }
  .law-enforcement .row-content .title {
    font-size: 22px;
    line-height: 26px;
    font-weight: bold;
    color: #333;
    margin-bottom: 15px;
  }
  @media (prefers-color-scheme: dark) {
    .law-enforcement .row-content .title {
      color: #cccccc;
    }
  }
  .law-enforcement .row-content .content {
    color: #6b6b6b;
    font-size: 14px;
    line-height: 18px;
    margin-bottom: 10px;
  }
  @media (prefers-color-scheme: dark) {
    .law-enforcement .row-content .content {
      color: #949494;
    }
  }
  .law-enforcement .row-line {
    margin-top: 20px;
    text-align: left;
  }
  .law-enforcement .row-line .title {
    font-size: 22px;
    font-weight: bold;
    color: #333;
    margin-bottom: 10px;
  }
  @media (prefers-color-scheme: dark) {
    .law-enforcement .row-line .title {
      color: #cccccc;
    }
  }
  .law-enforcement .row-line .content {
    font-size: 14px;
    color: #5e5e5e;
    margin-bottom: 15px;
    line-height: 18px;
  }
  @media (prefers-color-scheme: dark) {
    .law-enforcement .row-line .content {
      color: #a1a1a1;
    }
  }
  .law-enforcement .red {
    color: #f94a27;
  }
}
</style>
