<!-- about -->
<template>
  <div class="about-page">
    <div class="banner">
      <div class="banner-text">
        <p class="title">Our approach to safety</p>
        <p>
          You use our app to make new connections, share your story and learn
          about the world. Your safety is our priority to ensure you can
          interact meaningfully with new people.
        </p>
        <p>
          This space was created so that you can learn how to keep yourself
          safe, how we can help you and how our leading security technology
          works.
        </p>
      </div>
      <div class="banner-img">
        <img src="../../../assets/img/safety/large-1.png" alt="" />
      </div>
    </div>
    <div class="full-row">
      <dl class="img-text-warp">
        <dt class="pc">
          <img src="../../../assets/img/safety/img1-1.png" alt="" />
        </dt>
        <dd class="margin-l">
          <p class="row-title">
            We are here to give you support whenever you need it
          </p>
          <p class="row-p">
            It's easy to contact us if another user's behavior ever makes you
            feel unsafe, uncomfortable or concerned. We have a lot of technology
            in place to keep you safe, but please don't hesitate to report your
            concerns to our safety team--they are always ready to respond.
          </p>
          <p class="row-link">
            <router-link to="/safety/make-report"
              >Learn how to make a report</router-link
            >
          </p>
        </dd>
        <dt class="mobile">
          <img src="../../../assets/img/safety/img1-1.png" alt="" />
        </dt>
      </dl>
    </div>
    <div class="full-row">
      <dl class="img-text-warp">
        <dd class="margin-r">
          <p class="row-title">Community Guidelines</p>
          <p class="row-p">
            Safety and respect are key parts of what makes our diverse community
            so special. Ensure you understand how to be your best in this
            community and the rules we have for your safety.
          </p>
          <p class="row-link">
            <router-link to="/safety/community-guidelines"
              >See "Community Guidelines"</router-link
            >
          </p>
        </dd>
        <dt><img src="../../../assets/img/safety/img1-2.png" alt="" /></dt>
      </dl>
    </div>
    <div class="full-row">
      <dl class="img-text-warp">
        <dt class="pc">
          <img src="../../../assets/img/safety/img1-3.png" alt="" />
        </dt>
        <dd class="margin-l">
          <p class="row-title">You must be 18+ to join</p>
          <p class="row-p">
            To use the SUPERLIVE app and make new connections, you must be 18+.
            When signing up through Facebook, Google Play, the App Store or by
            mobile number, you must verify your age.
          </p>
          <p class="row-link">
            <router-link :to="{ path: '/safety/safety-tools#age' }"
              >Learn more about age verification</router-link
            >
          </p>
        </dd>
        <dt class="mobile">
          <img src="../../../assets/img/safety/img1-3.png" alt="" />
        </dt>
      </dl>
    </div>
    <div class="full-row">
      <dl class="img-text-warp">
        <dd class="margin-r">
          <p class="row-title">We never share your private data</p>
          <p class="row-p">
            We don't run ads, which means we don't make money by selling private
            data to any external third-party companies, nor do we share your
            exact location or phone number with other users. To make sure that
            you share only what you want to, you can also customize your privacy
            settings at any point.
          </p>
        </dd>
        <dt><img src="../../../assets/img/safety/img1-4.png" alt="" /></dt>
      </dl>
    </div>
    <div class="full-row">
      <dl class="img-text-warp">
        <dt class="pc">
          <img src="../../../assets/img/safety/img1-5.png" alt="" />
        </dt>
        <dd class="margin-l">
          <p class="row-title">Our technology works to keep you safe</p>
          <p class="row-p">
            We hope that every connection you make is meaningful, but this
            doesn't always happen--we've developed AI adaptive content blurring
            and content recognition tools to make sure that you're always
            talking to a real person and that inappropriate content is censored
            automatically.
          </p>
          <p class="row-link">
            <router-link to="/safety/safety-tools"
              >Learn more about our safety technology</router-link
            >
          </p>
        </dd>
        <dt class="mobile">
          <img src="../../../assets/img/safety/img1-5.png" alt="" />
        </dt>
      </dl>
    </div>
  </div>
</template>

<script>
export default {
  name: "about-our-safety-center",
  data() {
    return {};
  },
  methods: {},
};
</script>
<style lang="css" scoped>
@media (min-width: 768px) {
  .about-page .banner {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: linear-gradient(180deg, white 0%, #0386e8 100%);
    min-height: 100vh;
  }
  @media (prefers-color-scheme: dark) {
    .about-page .banner {
      background: linear-gradient(180deg, black 0%, #011729 100%);
    }
  }
  .about-page .banner-text {
    width: 999px;
    padding-top: 150px;
  }
  .about-page .banner-text p {
    margin-bottom: 15px;
    font-size: 24px;
    line-height: 28px;
    color: #5e5e5e;
  }
  @media (prefers-color-scheme: dark) {
    .about-page .banner-text p {
      color: #a1a1a1;
    }
  }
  .about-page .banner-text .title {
    color: #0386e8;
    font-size: 40px;
    font-weight: bold;
    text-align: center;
    margin-bottom: 40px;
  }
  .about-page .banner .banner-img {
    max-width: 999px;
  }
  .about-page .full-row {
    width: 100%;
    padding: 80px 0;
  }
  .about-page .full-row img {
    width: auto;
    max-width: inherit;
    height: inherit;
  }
  .about-page .full-row:nth-child(odd) {
    background-color: #fafafa;
  }
  @media (prefers-color-scheme: dark) {
    .about-page .full-row:nth-child(odd) {
      background-color: #0f0f0f;
    }
  }
  .about-page .full-row .img-text-warp {
    width: 990px;
    margin: 0 auto;
    display: flex;
    align-items: center;
  }
  .about-page .full-row .img-text-warp dt {
    width: 408px;
    height: 381px;
  }
  .about-page .full-row .img-text-warp dd {
    text-align: left;
  }
  .about-page .full-row .img-text-warp .row-title {
    font-size: 40px;
    line-height: 47px;
    font-weight: bold;
    color: #333;
    margin-bottom: 25px;
  }
  @media (prefers-color-scheme: dark) {
    .about-page .full-row .img-text-warp .row-title {
      color: #cccccc;
    }
  }
  .about-page .full-row .img-text-warp .row-p {
    color: #6b6b6b;
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 15px;
  }
  @media (prefers-color-scheme: dark) {
    .about-page .full-row .img-text-warp .row-p {
      color: #949494;
    }
  }
  .about-page .full-row .img-text-warp .row-link {
    color: #0386e8;
    font-size: 24px;
  }
  .about-page .margin-l {
    margin-left: 40px;
  }
  .about-page .margin-r {
    margin-right: 40px;
  }
  .about-page .mobile {
    display: none;
  }
}
@media (max-width: 768px) {
  .about-page .banner {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: linear-gradient(180deg, white 0%, #0386e8 100%);
  }
  @media (prefers-color-scheme: dark) {
    .about-page .banner {
      background: linear-gradient(180deg, black 0%, #011729 100%);
    }
  }
  .about-page .banner-text {
    padding: 25px;
  }
  .about-page .banner-text p {
    margin-bottom: 10px;
    font-size: 14px;
    line-height: 18px;
    color: #5e5e5e;
  }
  @media (prefers-color-scheme: dark) {
    .about-page .banner-text p {
      color: #a1a1a1;
    }
  }
  .about-page .banner-text .title {
    color: #0386e8;
    font-size: 22px;
    font-weight: bold;
    text-align: center;
    margin-bottom: 20px;
  }
  .about-page .banner .banner-img {
    padding: 0 25px;
  }
  .about-page .full-row {
    width: 100%;
    padding: 40px 25px;
  }
  .about-page .full-row:nth-child(odd) {
    background-color: #fafafa;
  }
  @media (prefers-color-scheme: dark) {
    .about-page .full-row:nth-child(odd) {
      background-color: #0f0f0f;
    }
  }
  .about-page .full-row .img-text-warp dt {
    padding: 20px 30px 0 30px;
  }
  .about-page .full-row .img-text-warp dt img {
    max-width: 100%;
  }
  .about-page .full-row .img-text-warp dd {
    text-align: left;
  }
  .about-page .full-row .img-text-warp .row-title {
    font-size: 22px;
    line-height: 25px;
    font-weight: bold;
    color: #333;
    margin-bottom: 10px;
  }
  @media (prefers-color-scheme: dark) {
    .about-page .full-row .img-text-warp .row-title {
      color: #cccccc;
    }
  }
  .about-page .full-row .img-text-warp .row-p {
    color: #6b6b6b;
    font-size: 14px;
    line-height: 18px;
    margin-bottom: 8px;
  }
  @media (prefers-color-scheme: dark) {
    .about-page .full-row .img-text-warp .row-p {
      color: #949494;
    }
  }
  .about-page .full-row .img-text-warp .row-link {
    color: #0386e8;
    font-size: 14px;
  }
  .about-page .margin-l {
    margin-left: 0;
  }
  .about-page .margin-r {
    margin-right: 0;
  }
  .about-page .pc {
    display: none;
  }
}
</style>
