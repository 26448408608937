<!-- about -->
<template>
  <div class="community-guidelines">
    <div class="banner">
      <div class="banner-text">
        <p class="title">Our Community Guidelines</p>
        <p>
          We developed our community guidelines to help make your experience
          safe, respectful and positive.
        </p>
        <p>
          All SUPERLIVE app users are expected to follow the guidelines. Your
          account may be suspended/deactivated if you do not follow Community
          Guidelines .
        </p>
      </div>
      <div class="banner-img">
        <img src="../../../assets/img/safety/large-3.png" alt="" />
      </div>
    </div>
    <div class="full-row">
      <dl class="img-text-warp">
        <dt class="pc">
          <img src="../../../assets/img/safety/img3-1.png" alt="" />
        </dt>
        <dd class="margin-l">
          <p class="row-title">Mutual Respect</p>
          <p class="row-p">
            Bullying or discrimination on the basis of social background,
            gender, ethnic group, race or sexual orientation is strictly
            forbidden on SUPERLIVE app. Please avoid talking about controversial
            issues, such as political or religious beliefs. If another user
            behaves in an offensive manner towards you,
            <router-link to="/safety/make-report">make a report</router-link>
            immediately.
          </p>
        </dd>
        <dt class="mobile">
          <img src="../../../assets/img/safety/img3-1.png" alt="" />
        </dt>
      </dl>
    </div>
    <div class="full-row">
      <dl class="img-text-warp">
        <dd class="margin-r">
          <p class="row-title">Rude and threatening behavior</p>
          <p class="row-p">
            Confrontational, aggressive and indecent behavior is forbidden and
            will not be tolerated. Making threats of physical harm will result
            in your account being deactivated, and the relevant local
            authorities may be contacted.
          </p>
        </dd>
        <dt><img src="../../../assets/img/safety/img3-2.png" alt="" /></dt>
      </dl>
    </div>
    <div class="full-row">
      <dl class="img-text-warp">
        <dt class="pc">
          <img src="../../../assets/img/safety/img3-3.png" alt="" />
        </dt>
        <dd class="margin-l">
          <p class="row-title">Careful what you share</p>
          <p class="row-p">
            Never share sexually explicit photos, images of violent acts,
            graphic or gory images or images promoting drug use. Don't share
            your personal financial information strangers online. Sharing any
            personal details may put you at unnecessary risk.
          </p>
        </dd>
        <dt class="mobile">
          <img src="../../../assets/img/safety/img3-3.png" alt="" />
        </dt>
      </dl>
    </div>
    <div class="full-row">
      <dl class="img-text-warp">
        <dd class="margin-r">
          <p class="row-title">Be yourself</p>
          <p class="row-p">
            Be yourself! That's the most important part of using SUPERLIVE app.
            Only use photos of yourself as your profile picture, never lie about
            your age or try impersonating someone else. SUPERLIVE app works best
            when everyone is comfortable, honest and open – it makes your
            interactions more meaningful.
          </p>
        </dd>
        <dt><img src="../../../assets/img/safety/img3-4.png" alt="" /></dt>
      </dl>
    </div>
    <div class="full-row">
      <dl class="img-text-warp">
        <dt class="pc">
          <img src="../../../assets/img/safety/img3-5.png" alt="" />
        </dt>
        <dd class="margin-l">
          <p class="row-title">Obey the law</p>
          <p class="row-p">
            Unlawful behavior will not be tolerated on the SUPERLIVE app.
            Encouraging violence, hate speech and organized crime is strictly
            forbidden and may be reported to local law enforcement. Sharing
            sexually explicit content of minors will be directly reported to
            local law enforcement.
          </p>
        </dd>
        <dt class="mobile">
          <img src="../../../assets/img/safety/img3-5.png" alt="" />
        </dt>
      </dl>
    </div>
    <div class="full-row">
      <dl class="img-text-warp">
        <dd class="margin-r">
          <p class="row-title">Protect the community</p>
          <p class="row-p">
            Please report immediately any behavior that violates the community
            guidelines. Not doing so may pose a safety risk for yourself or
            other community members.
          </p>
          <p class="row-link">
            <router-link to="/safety/make-report"
              >Make a report here</router-link
            >
          </p>
        </dd>
        <dt><img src="../../../assets/img/safety/img3-6.png" alt="" /></dt>
      </dl>
    </div>
    <div class="full-row">
      <dl class="img-text-warp">
        <dt class="pc">
          <img src="../../../assets/img/safety/img3-7.png" alt="" />
        </dt>
        <dd class="margin-l">
          <p class="row-title">What if you violate community guidelines?</p>
          <p class="row-p">
            If you are reported or breach our Community Guidelines, you will
            receive a warning. However, illegal behavior or behavior showing a
            clear lack of consideration will result in an instant ban. The
            guidelines outlined here aim to make the SUPERLIVE app community a
            friendly, safe and inclusive space.
          </p>
        </dd>
        <dt class="mobile">
          <img src="../../../assets/img/safety/img3-7.png" alt="" />
        </dt>
      </dl>
    </div>
    <div class="row-footer">
      <h2 class="title">Disclaimer</h2>
      <p class="content">
        This page is a resource outlining the principles of SUPERLIVE app’s
        Community Guidelines. For a detailed list of the community rules
        <a href="/static/Community Rules.pdf" target="_self">see here</a>. For
        terms of use,
        <a href="/terms-of-service/" target="_self">visit here</a>. For our
        privacy policy,
        <a href="/privacy-policy/" target="_self">visit here</a>.
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "our-community-guidelines",
  data() {
    return {};
  },
};
</script>
<style lang="css" scoped>
@media (min-width: 768px) {
  .community-guidelines .banner {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: linear-gradient(180deg, white 0%, #0386e8 100%);
    min-height: 100vh;
  }
  @media (prefers-color-scheme: dark) {
    .community-guidelines .banner {
      background: linear-gradient(180deg, black 0%, #011729 100%);
    }
  }
  .community-guidelines .banner-text {
    width: 999px;
    padding-top: 150px;
  }
  .community-guidelines .banner-text p {
    margin-bottom: 15px;
    font-size: 24px;
    line-height: 28px;
    color: #5e5e5e;
  }
  @media (prefers-color-scheme: dark) {
    .community-guidelines .banner-text p {
      color: #a1a1a1;
    }
  }
  .community-guidelines .banner-text .title {
    color: #0386e8;
    font-size: 40px;
    font-weight: bold;
    text-align: center;
    margin-bottom: 40px;
  }
  .community-guidelines .banner .banner-img {
    max-width: 999px;
  }
  .community-guidelines .full-row {
    width: 100%;
    padding: 80px 0;
  }
  .community-guidelines .full-row img {
    width: auto;
    max-width: inherit;
    height: inherit;
  }
  .community-guidelines .full-row:nth-child(odd) {
    background-color: #fafafa;
  }
  @media (prefers-color-scheme: dark) {
    .community-guidelines .full-row:nth-child(odd) {
      background-color: #0f0f0f;
    }
  }
  .community-guidelines .full-row .img-text-warp {
    width: 990px;
    margin: 0 auto;
    display: flex;
    align-items: center;
  }
  .community-guidelines .full-row .img-text-warp dt {
    width: 408px;
    height: 381px;
  }
  .community-guidelines .full-row .img-text-warp dd {
    text-align: left;
  }
  .community-guidelines .full-row .img-text-warp .row-title {
    font-size: 40px;
    line-height: 47px;
    font-weight: bold;
    color: #333;
    margin-bottom: 25px;
  }
  @media (prefers-color-scheme: dark) {
    .community-guidelines .full-row .img-text-warp .row-title {
      color: #cccccc;
    }
  }
  .community-guidelines .full-row .img-text-warp .row-p {
    color: #6b6b6b;
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 15px;
  }
  @media (prefers-color-scheme: dark) {
    .community-guidelines .full-row .img-text-warp .row-p {
      color: #949494;
    }
  }
  .community-guidelines .full-row .img-text-warp .row-link {
    color: #0386e8;
    font-size: 24px;
  }
  .community-guidelines .row-footer {
    width: 100%;
    padding: 30px calc(50vw - 485px) 90px;
    background-color: #fafafa;
  }
  @media (prefers-color-scheme: dark) {
    .community-guidelines .row-footer {
      background-color: #0f0f0f;
    }
  }
  .community-guidelines .row-footer .title {
    font-size: 40px;
    line-height: 47px;
    font-weight: bold;
    color: #333;
    margin-bottom: 25px;
  }
  @media (prefers-color-scheme: dark) {
    .community-guidelines .row-footer .title {
      color: #cccccc;
    }
  }
  .community-guidelines .row-footer .content {
    color: #6b6b6b;
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 15px;
  }
  @media (prefers-color-scheme: dark) {
    .community-guidelines .row-footer .content {
      color: #949494;
    }
  }
  .community-guidelines .margin-l {
    margin-left: 40px;
  }
  .community-guidelines .margin-r {
    margin-right: 40px;
  }
  .community-guidelines .mobile {
    display: none;
  }
}
@media (max-width: 768px) {
  .community-guidelines .banner {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: linear-gradient(180deg, white 0%, #0386e8 100%);
  }
  @media (prefers-color-scheme: dark) {
    .community-guidelines .banner {
      background: linear-gradient(180deg, black 0%, #011729 100%);
    }
  }
  .community-guidelines .banner-text {
    padding: 25px;
  }
  .community-guidelines .banner-text p {
    margin-bottom: 10px;
    font-size: 14px;
    line-height: 18px;
    color: #5e5e5e;
  }
  @media (prefers-color-scheme: dark) {
    .community-guidelines .banner-text p {
      color: #a1a1a1;
    }
  }
  .community-guidelines .banner-text .title {
    color: #0386e8;
    font-size: 22px;
    font-weight: bold;
    text-align: center;
    margin-bottom: 20px;
  }
  .community-guidelines .banner .banner-img {
    padding: 0 25px;
  }
  .community-guidelines .full-row {
    width: 100%;
    padding: 40px 25px;
  }
  .community-guidelines .full-row:nth-child(odd) {
    background-color: #fafafa;
  }
  @media (prefers-color-scheme: dark) {
    .community-guidelines .full-row:nth-child(odd) {
      background-color: #0f0f0f;
    }
  }
  .community-guidelines .full-row .img-text-warp dt {
    padding: 20px 30px 0 30px;
  }
  .community-guidelines .full-row .img-text-warp dt img {
    max-width: 100%;
  }
  .community-guidelines .full-row .img-text-warp dd {
    text-align: left;
  }
  .community-guidelines .full-row .img-text-warp .row-title {
    font-size: 22px;
    line-height: 25px;
    font-weight: bold;
    color: #333;
    margin-bottom: 10px;
  }
  @media (prefers-color-scheme: dark) {
    .community-guidelines .full-row .img-text-warp .row-title {
      color: #cccccc;
    }
  }
  .community-guidelines .full-row .img-text-warp .row-p {
    color: #6b6b6b;
    font-size: 14px;
    line-height: 18px;
    margin-bottom: 8px;
  }
  @media (prefers-color-scheme: dark) {
    .community-guidelines .full-row .img-text-warp .row-p {
      color: #949494;
    }
  }
  .community-guidelines .full-row .img-text-warp .row-link {
    color: #0386e8;
    font-size: 14px;
  }
  .community-guidelines .margin-l {
    margin-left: 0;
  }
  .community-guidelines .margin-r {
    margin-right: 0;
  }
  .community-guidelines .row-footer {
    padding: 25px 25px 50px 25px;
  }
  .community-guidelines .row-footer .title {
    font-size: 22px;
    line-height: 25px;
    font-weight: bold;
    color: #333;
    margin-bottom: 10px;
  }
  @media (prefers-color-scheme: dark) {
    .community-guidelines .row-footer .title {
      color: #cccccc;
    }
  }
  .community-guidelines .row-footer .content {
    color: #6b6b6b;
    font-size: 14px;
    line-height: 16px;
  }
  @media (prefers-color-scheme: dark) {
    .community-guidelines .row-footer .content {
      color: #949494;
    }
  }
  .community-guidelines .pc {
    display: none;
  }
}
</style>
