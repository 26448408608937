<!--  -->
<template>
  <div class="safety-tools">
    <div class="row-content">
      <h2 class="title">Safety Tools</h2>
      <p class="content">
        We would love for every member of our community to have a positive
        experience while using the SUPERLIVE app. That means being as safe and
        secure as possible.
      </p>
      <p class="content">
        And that's why we've built in several robust safety features into the
        app, including age verification, facial recognition, Artificial
        Intelligence Content Moderation, and Human-Assisted Anonymized Content
        Moderation.
      </p>
    </div>
    <div class="full-row">
      <dl class="img-text-warp">
        <dt class="pc">
          <img src="../../../assets/img/safety/img1-1.png" alt="" />
        </dt>
        <dd class="margin-l">
          <p class="row-title">Connect with a real person</p>
          <p class="row-p">
            Both users who have matched on the app must have their faces visible
            on the camera during video chatting. If not, our content moderation
            features will automatically detect a problem and intervene to help
            our users. This feature ensures that you are always connected with a
            real person.
          </p>
        </dd>
        <dt class="mobile">
          <img src="../../../assets/img/safety/img1-1.png" alt="" />
        </dt>
      </dl>
    </div>
    <div class="full-row">
      <dl class="img-text-warp">
        <dd class="margin-r">
          <p class="row-title">
            Manual and Artificial Intelligence Based Content Moderation
          </p>
          <p class="row-p">
            While we are unable to control what each user does, we have been
            working hard to ensure that you're not exposed to any offensive or
            unpleasant content on our platform. To keep our community safe, we
            have built tools to detect and stop content that violates our
            community guidelines such as violence, nudity or offensive images.
          </p>
          <p class="row-p">
            Reports from our user are also analyzed with help of our A.I. based
            tools with the help from human moderators.
          </p>
        </dd>
        <dt class="ai_tools">
          <img src="../../../assets/img/safety/img1-5.png" alt="" />
        </dt>
      </dl>
    </div>
    <div class="full-row" id="age">
      <dl class="img-text-warp">
        <dt class="pc">
          <img src="../../../assets/img/safety/img1-3.png" alt="" />
        </dt>
        <dd class="margin-l">
          <p class="row-title">Age verification upon signing up</p>
          <p class="row-p">
            Our community is strictly for those who are 18 years of age or
            older. When you sign up - whether it's through Facebook, Google
            Play, the App Store or by entering a phone number - you will need to
            verify your age.
          </p>
        </dd>
        <dt class="mobile">
          <img src="../../../assets/img/safety/img1-3.png" alt="" />
        </dt>
      </dl>
    </div>
    <div class="full-row">
      <dl class="img-text-warp">
        <dd class="margin-r">
          <p class="row-title">Block and Manual Reports</p>
          <p class="row-p">
            You can choose to block a user you've added or a user that has
            directly messaged you in your chat settings. After being blocked,
            that user will be added to your blacklist, and they will not be able
            to send messages to you.
          </p>
          <p class="row-p">
            You can also choose to "Block & Report" a user. This will also
            generate a report for the user being blocked. Later, our Automatic
            and Manual Content Moderation system will assess the report and take
            action to protect our community.
          </p>
          <p class="row-link">
            <router-link to="/safety/make-report"
              >Learn how to make a report</router-link
            >
          </p>
        </dd>
        <dt class="block_video">
          <video
            autoplay
            muted
            playsinline
            :src="blockVideoSources[blockVideoIndex]"
            @ended="blockVideoEnded"
          ></video>
        </dt>
      </dl>
    </div>
    <!-- <div class="full-row">
      <dl class="img-text-warp">
        <! -- <dt class="pc"><switch-img :images="blockM55" :time="3.5"></switch-img></dt>
        <dd class="margin-l">
          <p class="row-title">Manual reporting</p>
          <p class="row-p">It's easy to submit a report in the app. To do this, either click on the report symbol when matched with someone or go to your chat history to report the user. To find out more about reporting a user, <router-link to="/safety/make-report">go here</router-link>.</p>
        </dd>
        <! -- <dt class="mobile"> <switch-img :images="blockM55" :time="3.5"></switch-img></dt>
      </dl>
    </div> -->
    <div class="row-footer">
      <h2 class="title">Disclaimers</h2>
      <p class="content">
        Screenshots might be different from your in-app experience. The content
        provided on the website is aimed only for informational purposes and
        might not apply to your country, region or city. It's subject to change
        and may be updated without notice.
      </p>
    </div>
  </div>
</template>

<script>
import SwitchImg from "./switch-img";

const blockVideoSources = ["/static/gif1.mp4"];

export default {
  name: "safety-tools",
  data() {
    return {
      blockVideoSources: blockVideoSources,
      blockVideoIndex: 0,
      // blockM54: [
      //   require('../../../assets/img/safety/img5-4.png'),
      //   require('../../../assets/img/safety/img5-4h.png')
      // ],
      // blockM55: [
      //   require('../../../assets/img/safety/img5-5.png'),
      //   require('../../../assets/img/safety/img5-5h.png')
      // ]
    };
  },
  methods: {
    blockVideoEnded() {
      console.log("blockVideoEnded");
      this.blockVideoIndex =
        (this.blockVideoIndex + 1) % this.blockVideoSources.length;
    },
  },
  components: {
    // 'switch-img': SwitchImg
  },
};
</script>
<style lang="css" scoped>
@media (min-width: 768px) {
  .safety-tools .row-content {
    padding-top: 140px;
    width: 970px;
    margin: 0 auto 0 auto;
  }
  .safety-tools .row-content .title {
    font-size: 40px;
    line-height: 47px;
    font-weight: bold;
    color: #333;
    margin-bottom: 25px;
  }
  @media (prefers-color-scheme: dark) {
    .safety-tools .row-content .title {
      color: #cccccc;
    }
  }
  .safety-tools .row-content .content {
    color: #6b6b6b;
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 15px;
  }
  @media (prefers-color-scheme: dark) {
    .safety-tools .row-content .content {
      color: #949494;
    }
  }
  .safety-tools .full-row {
    width: 100%;
    padding: 40px 0;
  }
  .safety-tools .full-row img {
    width: auto;
    max-width: inherit;
    height: inherit;
  }
  .safety-tools .full-row:nth-child(even) {
    background-color: #fafafa;
  }
  @media (prefers-color-scheme: dark) {
    .safety-tools .full-row:nth-child(even) {
      background-color: #0f0f0f;
    }
  }
  .safety-tools .full-row .img-text-warp {
    width: 990px;
    margin: 0 auto;
    display: flex;
    align-items: center;
  }
  .safety-tools .full-row .img-text-warp dt:not(.block_video) {
    pointer-events: none;
    height: 240px;
  }
  .safety-tools .full-row .img-text-warp dt.ai_tools {
    height: 280px;
    margin: -40px;
  }
  /* .safety-tools .full-row .img-text-warp dt.block_video {
  } */
  .safety-tools .full-row .img-text-warp dd {
    text-align: left;
  }
  .safety-tools .full-row .img-text-warp .row-title {
    font-size: 40px;
    line-height: 47px;
    font-weight: bold;
    color: #333;
    margin-bottom: 25px;
  }
  @media (prefers-color-scheme: dark) {
    .safety-tools .full-row .img-text-warp .row-title {
      color: #cccccc;
    }
  }
  .safety-tools .full-row .img-text-warp .row-p {
    color: #6b6b6b;
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 15px;
  }
  @media (prefers-color-scheme: dark) {
    .safety-tools .full-row .img-text-warp .row-p {
      color: #949494;
    }
  }
  .safety-tools .full-row .img-text-warp .row-link {
    color: #0386e8;
    font-size: 24px;
  }
  .safety-tools .row-footer {
    width: 100%;
    padding: 30px calc(50vw - 485px) 90px;
    background-color: #fafafa;
  }
  @media (prefers-color-scheme: dark) {
    .safety-tools .row-footer {
      background-color: #0f0f0f;
    }
  }
  .safety-tools .row-footer .title {
    font-size: 40px;
    line-height: 47px;
    font-weight: bold;
    color: #333;
    margin-bottom: 25px;
  }
  @media (prefers-color-scheme: dark) {
    .safety-tools .row-footer .title {
      color: #cccccc;
    }
  }
  .safety-tools .row-footer .content {
    color: #6b6b6b;
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 15px;
  }
  @media (prefers-color-scheme: dark) {
    .safety-tools .row-footer .content {
      color: #949494;
    }
  }
  .safety-tools .margin-l {
    margin-left: 40px;
  }
  .safety-tools .margin-r {
    margin-right: 40px;
  }
  .safety-tools .mobile {
    display: none;
  }
  .safety-tools .block_gif {
    height: 650px;
    width: auto;
  }
}
@media (max-width: 768px) {
  .safety-tools .row-content {
    padding: 40px 25px 0 25px;
  }
  .safety-tools .row-content .title {
    font-size: 22px;
    line-height: 26px;
    font-weight: bold;
    color: #333;
    margin-bottom: 10px;
  }
  @media (prefers-color-scheme: dark) {
    .safety-tools .row-content .title {
      color: #cccccc;
    }
  }
  .safety-tools .row-content .content {
    color: #6b6b6b;
    font-size: 14px;
    line-height: 16px;
    margin-bottom: 15px;
  }
  @media (prefers-color-scheme: dark) {
    .safety-tools .row-content .content {
      color: #949494;
    }
  }
  .safety-tools .full-row {
    width: 100%;
    padding: 20px 25px;
  }
  .safety-tools .full-row:nth-child(even) {
    background-color: #fafafa;
  }
  @media (prefers-color-scheme: dark) {
    .safety-tools .full-row:nth-child(even) {
      background-color: #0f0f0f;
    }
  }
  .safety-tools .full-row .img-text-warp dt {
    padding: 20px 30px 0 30px;
  }
  .safety-tools .full-row .img-text-warp dt img {
    max-width: 100%;
  }
  .safety-tools .full-row .img-text-warp dt.block_video {
    height: 300px;
    text-align: center;
  }
  .safety-tools .full-row .img-text-warp dt.block_video video {
    height: 280px;
    width: auto;
    max-width: 100%;
  }
  .safety-tools .full-row .img-text-warp dd {
    text-align: left;
  }
  .safety-tools .full-row .img-text-warp .row-title {
    font-size: 22px;
    line-height: 26px;
    font-weight: bold;
    color: #333;
    margin-bottom: 14px;
  }
  @media (prefers-color-scheme: dark) {
    .safety-tools .full-row .img-text-warp .row-title {
      color: #cccccc;
    }
  }
  .safety-tools .full-row .img-text-warp .row-p {
    color: #6b6b6b;
    font-size: 14px;
    line-height: 18px;
    margin-bottom: 15px;
  }
  @media (prefers-color-scheme: dark) {
    .safety-tools .full-row .img-text-warp .row-p {
      color: #949494;
    }
  }
  .safety-tools .full-row .img-text-warp .row-link {
    color: #0386e8;
    font-size: 24px;
  }
  .safety-tools .row-footer {
    padding: 15px 25px 45px 25px;
    background-color: #fafafa;
  }
  @media (prefers-color-scheme: dark) {
    .safety-tools .row-footer {
      background-color: #0f0f0f;
    }
  }
  .safety-tools .row-footer .title {
    font-size: 22px;
    line-height: 26px;
    font-weight: bold;
    color: #333;
    margin-bottom: 25px;
  }
  @media (prefers-color-scheme: dark) {
    .safety-tools .row-footer .title {
      color: #cccccc;
    }
  }
  .safety-tools .row-footer .content {
    color: #6b6b6b;
    font-size: 14px;
    line-height: 18px;
    margin-bottom: 15px;
  }
  @media (prefers-color-scheme: dark) {
    .safety-tools .row-footer .content {
      color: #949494;
    }
  }
  .safety-tools .margin-l {
    margin-left: 0;
  }
  .safety-tools .margin-r {
    margin-right: 0;
  }
  .safety-tools .pc {
    display: none;
  }
  .safety-tools .block_gif {
    height: 450px;
    width: auto;
  }
}
</style>
