<template>
  <div class="top-fixed">
    <div class="top-warp">
      <div class="mobile-mask" v-show="menuShow"></div>
      <div class="mobile-menu" :class="{ show: menuShow }">
        <div class="menu-header go-home">
          <div class="icon" @click="goHome">
            <b-icon icon="arrow-left" size="is-small"></b-icon>
          </div>
        </div>
        <hr style="margin: 0" />
        <div class="menu-header">
          <h1 class="logo-text">Safety Center</h1>
          <h5 class="menu-close" @click="menuShow = false">
            <img src="../../../assets/img/safety/close.png" alt="close" />
          </h5>
        </div>
        <ul class="nav-bar">
          <li
            v-for="(item, index) in menuData"
            @click="switchMenu(item.path)"
            :class="{ active: item.path === currentPath }"
            :key="item + '' + index"
          >
            {{ item.name }}
          </li>
        </ul>
      </div>
      <div class="header-bar">
        <h2 class="logo" @click="showMenu">
          <img alt="" />
        </h2>
        <h1 class="logo-text menu-button">Safety Center</h1>
        <ul class="icon-list">
          <li>
            <a
              :href="'mailto:help@superlivellc.com?subject=SUPERLIVE Safety Request'"
              ><img src="../../../assets/img/safety/mail.png" alt=""
            /></a>
          </li>
        </ul>
      </div>
      <ul class="nav-bar">
        <li
          v-for="(item, index) in menuData"
          @click="switchMenu(item.path)"
          :class="{ active: item.path === currentPath }"
          :key="item + '' + index"
        >
          {{ item.name }}
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "top",
  data() {
    return {
      domain: location.host,
      currentPath: "",
      menuShow: false,
      menuData: [
        {
          name: "Our Safety Policy",
          path: "/safety/",
        },
        {
          name: "Tips for Safety",
          path: "/safety/safety-tips",
        },
        {
          name: "Our Community Guidelines",
          path: "/safety/community-guidelines",
        },
        {
          name: "Child Safety Policy",
          path: "/safety/child-safety",
        },
        {
          name: "Online Social Awareness",
          path: "/safety/social-awareness",
        },
        {
          name: "Our Safety Tools",
          path: "/safety/safety-tools",
        },
        {
          name: "Make a Report",
          path: "/safety/make-report",
        },
        {
          name: "For Law Enforcement",
          path: "/safety/law-enforcement",
        },
        {
          name: "FAQs",
          path: "/safety/faqs",
        },
      ],
    };
  },
  created() {
    this.currentPath = this.$route.path;
  },
  watch: {
    "$route.path"(v) {
      console.log(v);
      this.currentPath = v;
    },
  },
  methods: {
    switchMenu(path) {
      if (path === this.currentPath) return;
      this.currentPath = path;
      this.$router.push({ path });
      this.menuShow = false;
    },
    showMenu() {
      this.menuShow = true;
      let w =
        window.innerWidth ||
        document.documentElement.clientWidth ||
        document.body.clientWidth;
      if (w < 750) {
        this.menuShow = true;
      } else {
        this.goHome();
      }
    },
    goHome() {
      this.$router.push("/");
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="css">
@media (min-width: 1100px) {
  .top-warp {
    position: fixed;
    top: 0;
    left: 100px;
    right: 0px;
    z-index: 99999;
    background-color: #fff;
    background-color: rgba(255, 255, 255, 0.5);
    height: 100px;
    backdrop-filter: blur(20px);
    backdrop-filter: saturate(180%) blur(20px);
  }
  @media (prefers-color-scheme: dark) {
    .top-warp {
      background-color: #000;
      background-color: rgba(0, 0, 0, 0.5);
    }
  }
  .top-warp .header-bar {
    display: flex;
    padding: 12px;
  }
  .top-warp .logo-text {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: left;
    font-weight: bold;
    font-size: 24px;
    color: #333;
  }
  @media (prefers-color-scheme: dark) {
    .top-warp .logo-text {
      color: #cccccc;
    }
  }
  .top-warp .logo {
    width: 85px;
    height: 24px;
  }
  .top-warp .logo img {
    max-width: 100%;
  }
  .top-warp .icon-list {
    display: flex;
  }
  .top-warp .icon-list li {
    width: 24px;
    height: 24px;
    margin-left: 20px;
  }
  .top-warp .icon-list li img {
    max-width: 100%;
  }
  .top-warp .nav-bar {
    display: flex;
    margin: 0 auto;

    width: 100%;

    /* margin: 35px auto 0 auto; */
    justify-content: space-between;
    padding: 20px 20px;
  }
  .top-warp .nav-bar li {
    text-align: center;
    cursor: pointer;
    font-size: 14px;
    color: #333;
    position: relative;
  }
  @media (prefers-color-scheme: dark) {
    .top-warp .nav-bar li {
      color: white;
    }
  }
  .top-warp .nav-bar li.active::before {
    content: "";
    position: absolute;
    left: 50%;
    bottom: -10px;
    width: 38px;
    height: 3px;
    background-color: #0386e8;
    transform: translateX(-50%);
  }
  .top-warp .mobile-menu {
    display: none;
  }
  .top-warp .mobile-mask {
    display: none;
  }
}
@media (max-width: 1100px) {
  .top-warp {
    z-index: 99999;
    width: 100%;
    height: 56px;
  }
  @media (prefers-color-scheme: dark) {
    .top-warp {
      background-color: #000;
    }
  }
  .top-warp .header-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 25px 0 25px;
  }
  .top-warp .logo-text {
    text-align: left;
    font-weight: bold;
    font-size: 16px;
    color: #333;
    position: relative;
  }
  @media (prefers-color-scheme: dark) {
    .top-warp .logo-text {
      color: #cccccc;
    }
  }
  .top-warp .header-bar .logo::before {
    content: "";
    position: absolute;
    top: 7px;
    left: -7px;
    width: 10px;
    height: 16px;
    background: url("../../../assets/img/safety/menu-icon.png") no-repeat;
    background-size: 10px 16px;
  }
  @media (prefers-color-scheme: dark) {
    .top-warp .header-bar .logo::before {
      content: "";
      position: absolute;
      top: 7px;
      left: -7px;
      width: 10px;
      height: 16px;
      background: url("../../../assets/img/safety/menu-icon-light.png")
        no-repeat;
      background-size: 10px 16px;
    }
  }
  .top-warp .logo {
    width: 85px;
    position: relative;
  }
  .top-warp .logo img {
    max-width: 100%;
  }
  .top-warp .icon-list {
    display: none;
  }
  .top-warp .icon-list li {
    width: 24px;
    height: 24px;
    margin-left: 20px;
  }
  .top-warp .icon-list li img {
    max-width: 100%;
  }
  .top-warp .nav-bar {
    display: none;
  }
  .top-warp .mobile-mask {
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 1;
  }
  .top-warp .mobile-menu {
    position: absolute;
    left: -100%;
    top: 0;
    height: 100%;
    width: 100%;
    z-index: 998;
    background-color: #ffffff;
    transition: all 0.3s linear;
  }
  @media (prefers-color-scheme: dark) {
    .top-warp .mobile-menu {
      background-color: #0f0f0f;
    }
  }
  .top-warp .mobile-menu.show {
    left: 0;
    right: 0;
  }
  .top-warp .mobile-menu .menu-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 25px;
  }
  @media (prefers-color-scheme: dark) {
    .top-warp .mobile-menu .menu-header .logo-text {
      color: white;
    }
  }
  .top-warp .mobile-menu .menu-header .logo {
    width: 50px;
  }
  .top-warp .mobile-menu .menu-header .logo img {
    max-width: 100%;
  }
  .top-warp .mobile-menu .menu-header.go-home {
    justify-content: start;
  }
  @media (prefers-color-scheme: dark) {
    .top-warp .mobile-menu .menu-header.go-home {
      color: white;
    }
    .mobile-menu hr {
      background-color: #333;
    }
  }
  .top-warp .mobile-menu .menu-header.go-home .text {
    text-align: left;
    font-weight: bold;
    font-size: 16px;
    color: #333;
  }
  .top-warp .mobile-menu .menu-header.go-home .logo {
    width: 85px;
    position: relative;
  }
  .top-warp .mobile-menu .menu-header.go-home .logo img {
    max-width: 100%;
  }
  .top-warp .mobile-menu .nav-bar {
    display: block;
  }
  .top-warp .mobile-menu .nav-bar li {
    text-align: left;
    height: 52px;
    cursor: pointer;
    font-size: 16px;
    color: #333;
    border-bottom: solid 1px #f4f4f4;
    display: flex;
    align-items: center;
    padding: 0 25px;
  }
  @media (prefers-color-scheme: dark) {
    .top-warp .mobile-menu .nav-bar li {
      color: white;
      border-bottom: solid 1px #333;
    }
  }
  .top-warp .mobile-menu .nav-bar li.active {
    background-color: #f4f4f4;
  }
  @media (prefers-color-scheme: dark) {
    .top-warp .mobile-menu .nav-bar li.active {
      background-color: #222;
    }
  }
  .top-warp .mobile-menu .nav-bar li:last-child {
    border: none;
  }
}
</style>
